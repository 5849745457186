/* This example requires Tailwind CSS v2.0+ */

import React, { useState, useEffect } from 'react';

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from '../Layout/Layout';
import { graphql } from 'gatsby';
import ReactPlayer from 'react-player';
import VideoPlayer from '../Components/VideoPlayer/VideoPlayer';
import Seo from '../Layout/Seo';
import { PageTitle } from '../Components/Headings/PageTitle';


const VideoWorkDetails = ({ data }) => {
	useEffect(() => {
		window.scrollTo(0, 0)
	  }, [data])

	console.log(data);
	const { html } = data.markdownRemark;
	const {
		title,
		description,
		thumb,
		tag,
		slug,
		link,
		host
	} = data.markdownRemark.frontmatter;

	const seoImage = data.markdownRemark.frontmatter.thumb.publicURL

	return (
		<Layout>
			<Seo
				title={title}
				description={description}
				url={"www.kinzicastudio.com/works/library-music/" + slug}
				keywords={["", "Kinzica Studio Video", "video production", title, tag]}
				image={seoImage}
			/>
			<section className="bg-kinzica-dark">
				<div className="text-center sm:px-24 px-8">

					<PageTitle title={title} />

					{/* Player */}



					<div className="flex flex-col items-center justify-center">
						<div className="lg:w-2/3 w-full">
							<VideoPlayer
								link={link}
								controls={true}
							/>
						</div>
					</div>



					<div className="mx-auto mt-24 w-full lg:w-2/3">
						<h1 className="mt-8 text-start text-4xl  text-white">
							Client: <span className="underline">{title}</span>
						</h1>
						<p className="text-start text-white text-2xl">
							Type: <span className="underline">{tag}</span>
						</p>
						<p className='mt-4 border-b-white border-b'></p>
						<div
							dangerouslySetInnerHTML={{ __html: html }}
							className="font-mont font-thin leading-snug
						mt-16 w-full mx-auto text-white text-3xl text-start"
						/>
					</div>
				</div>
			</section >
		</Layout >
	)

}



export default VideoWorkDetails;


export const query = graphql`
	query VideoWorkDetails($slug: String) {
		markdownRemark(frontmatter: {slug: {eq: $slug}}) {
			id
			html
			frontmatter {
				slug
				tag
				title
				client
				collaborators
				date
				description
				link
				host
				thumb {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	}
	`
