import React from "react";
import ReactPlayer from "react-player";
import "./VideoPlayer.css";

const VideoPlayer = (props) => {


	const { link, controls } = props;

	return (
		<div className="player-wrapper">
			<ReactPlayer
				url={link}
				className="react-player"
				width="100%"
				height="100%"
				controls={controls}
			/>
		</div>
	)
};

export default VideoPlayer;
